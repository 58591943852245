import './App.css';

function App() {
  return (
    <div className="App">
        <p>
          Magellanic Sensing
        </p>
        <iframe
          title="ISS Feed"
          width="100%"
          height="700"
          src="https://ustream.tv/embed/17074538"
          scrolling="no"
          allowfullscreen webkitallowfullscreen
          frameborder="0">
        </iframe>
    </div>
  );
}

export default App;
